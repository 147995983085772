<!-- 可视化 -->
<template>
    <div>
        <lazyImg :src="ossUrl + 'banner.png'" class="imgTransition" alt="" style="width:100%"></lazyImg>
        <div style="padding:30px 24px 40px 24px">
            <div class="globalText">
                <p style="margin-bottom: 14px;">
                    艺术源于生活，科学始于探索。在我们用客观指数描述T细胞多样性的同时，也希望将此科学的数字用更具视觉化的方式进行呈现，“小宇宙”图即诞生于此。
                </p>
                <p style="margin-bottom: 14px;">
                    “小宇宙”图中的每一个圆形色块代表一种T细胞群体，色块的面积大小代表该T细胞群体的相对数量。 </p>
                多样性的高低，与机体抵抗力的强弱有关。一般来说，T细胞群体越多，数量越均一，机体抵抗力越强。
            </div>

            <div style="position:relative;margin-top: 20px;">
                <div v-viewer>
                    <img :src="ossUrl + 'vis.png'" alt="" style="width:260px;height:260px;position:absolute"
                        class="center imgTransition" />
                </div>
                <img :src="ossUrl + 'border.png'"
                    style="width: 330px;margin:auto;position: relative;pointer-events: none;" />
            </div>

            <div class="userInfo">
                <div>姓名：康小蓓</div>
                <div>日期：2022-12-23</div>
            </div>
            <div class="globalText">如同您仰望星空感叹璀璨星河的瑰丽一样，我们也希望您探索自身，发现自己“小宇宙”的神奇！</div>
        </div>
    </div>
</template>
<script>
const ossUrl = 'https://kindstarbiotech.oss-cn-hangzhou.aliyuncs.com/rosefinch/static/h5/'
export default {
    data() {
        return {
            ossUrl: ossUrl
        }
    },
    created() {
    },
    mounted() {
        // 页面置顶
        document.documentElement.scrollIntoView()
    },
    computed: {
    },
    methods: {
    },
}
</script>
<style lang="scss" scoped>
.center {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.userInfo {
    width: 275px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #333333;
}
</style>
